<template>
  <div id="acceptance">
    <el-dialog
      :title="acceptanceFormTitle"
      width="680px"
      :visible.sync="acceptanceDialogVisible"
      :close-on-click-modal="false"
      @close="acceptanceDialogClose"
    >
      <el-form
        ref="acceptanceFormRef"
        :model="acceptanceForm"
        :rules="acceptanceFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备名称" prop="deviceId">
              <el-select
                v-model="acceptanceForm.deviceId"
                placeholder="请选择设备"
                clearable
                @change="deviceChange"
              >
                <el-option
                  v-for="item in deviceList"
                  :key="item.id"
                  :label="item.deviceName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="本厂编号" prop="deviceNo">
              <el-input v-model="acceptanceForm.deviceNo" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格型号" prop="spec">
              <el-input v-model="acceptanceForm.spec" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产厂家" prop="manufacturer">
              <el-input v-model="acceptanceForm.manufacturer" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="进厂日期" prop="enterFactoryDate">
              <el-input v-model="acceptanceForm.enterFactoryDate" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="价格" prop="price">
              <el-input v-model="acceptanceForm.price" placeholder="请输入价格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="主要技术参数" prop="technicalParameter">
              <el-input
                v-model="acceptanceForm.technicalParameter"
                placeholder="请输入主要技术参数"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="随机附件及数量">
              <el-scrollbar style="height: 95.2px">
                <el-upload
                  ref="uploadRef"
                  action=""
                  :http-request="handleHttp"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  multiple
                >
                  <el-button size="small" type="primary">
                    点击上传
                  </el-button>
                </el-upload>
              </el-scrollbar>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="安装调试情况" prop="installationSituation">
              <el-input
                v-model="acceptanceForm.installationSituation"
                placeholder="请输入安装调试情况"
                type="textarea"
                :rows="2"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="验收结论" prop="acceptanceConclusion">
              <el-input
                v-model="acceptanceForm.acceptanceConclusion"
                placeholder="请输入验收结论"
                type="textarea"
                :rows="2"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="参加验收人员" prop="acceptanceStaff">
              <el-input
                v-model="acceptanceForm.acceptanceStaff"
                placeholder="请输入参加验收人员"
                type="textarea"
                :rows="2"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="acceptanceForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="2"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用部门确认" prop="useDeptConfirm">
              <el-input v-model="acceptanceForm.useDeptConfirm" placeholder="请输入使用部门确认" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="采购部门签名" prop="purchaseDeptConfirm">
              <el-input v-model="acceptanceForm.purchaseDeptConfirm" placeholder="请输入采购部门签名" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认时间" prop="userDeptTime">
              <el-date-picker v-model="acceptanceForm.userDeptTime" placeholder="请选择确认时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签名时间" prop="purchaseDeptTime">
              <el-date-picker v-model="acceptanceForm.purchaseDeptTime" placeholder="请选择签名时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="acceptanceDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="acceptanceFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备名称">
        <el-input v-model="searchForm.deviceName" placeholder="请输入设备名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="acceptancePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="deviceName" label="设备名称" />
      <el-table-column prop="deviceNo" label="本厂编号" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="manufacturer" label="生产厂家" />
      <el-table-column label="进厂日期">
        <template slot-scope="scope">
          <span v-if="scope.row.enterFactoryDate">{{ scope.row.enterFactoryDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="acceptancePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addAcceptance, deleteAcceptance, updateAcceptance, selectAcceptanceInfo, selectAcceptanceList } from '@/api/device/acceptance'
import { selectBaseInfoList } from '@/api/device/baseInfo'
import { upload } from '@/api/main/file'

export default {
  data () {
    return {
      acceptanceDialogVisible: false,
      acceptanceFormTitle: '',
      acceptanceForm: {
        id: '',
        deviceId: '',
        deviceName: '',
        deviceNo: '',
        spec: '',
        manufacturer: '',
        enterFactoryDate: '',
        price: '',
        technicalParameter: '',
        annexName: '',
        annexUrl: '',
        installationSituation: '',
        acceptanceConclusion: '',
        acceptanceStaff: '',
        remarks: '',
        useDeptConfirm: '',
        userDeptTime: '',
        purchaseDeptConfirm: '',
        purchaseDeptTime: ''
      },
      acceptanceFormRules: {
        deviceId: [{ required: true, message: '设备不能为空', trigger: ['blur', 'change']}],
        price: [{ required: true, message: '价格不能为空', trigger: ['blur', 'change']}]
      },
      acceptancePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceName: ''
      },
      deviceList: [],
      fileList: []
    }
  },
  created () {
    selectBaseInfoList().then(res => {
      this.deviceList = res.list
    })
    selectAcceptanceList(this.searchForm).then(res => {
      this.acceptancePage = res
    })
  },
  methods: {
    acceptanceDialogClose () {
      this.$refs.acceptanceFormRef.resetFields()
      this.$refs.uploadRef.clearFiles()
      this.fileList = []
    },
    acceptanceFormSubmit () {
      if (this.acceptanceFormTitle === '验收单详情') {
        this.acceptanceDialogVisible = false
        return
      }
      this.$refs.acceptanceFormRef.validate(async valid => {
        if (valid) {
          this.acceptanceForm.annexUrl = this.fileList.map(file => file.path).join(',')
          this.acceptanceForm.annexName = this.fileList.map(file => file.name).join(',')
          if (this.acceptanceFormTitle === '新增验收单') {
            await addAcceptance(this.acceptanceForm)
          } else if (this.acceptanceFormTitle === '修改验收单') {
            await updateAcceptance(this.acceptanceForm)
          }
          this.acceptancePage = await selectAcceptanceList(this.searchForm)
          this.acceptanceDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.acceptanceFormTitle = '新增验收单'
      this.acceptanceDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteAcceptance(row.id)
        if (this.acceptancePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.acceptancePage = await selectAcceptanceList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.acceptanceFormTitle = '修改验收单'
      this.acceptanceDialogVisible = true
      this.selectAcceptanceInfoById(row.id)
    },
    handleInfo (index, row) {
      this.acceptanceFormTitle = '验收单详情'
      this.acceptanceDialogVisible = true
      this.selectAcceptanceInfoById(row.id)
    },
    selectAcceptanceInfoById (id) {
      selectAcceptanceInfo(id).then(res => {
        this.acceptanceForm.id = res.id
        this.acceptanceForm.deviceId = res.deviceId
        this.acceptanceForm.deviceName = res.deviceName
        this.acceptanceForm.deviceNo = res.deviceNo
        this.acceptanceForm.spec = res.spec
        this.acceptanceForm.manufacturer = res.manufacturer
        this.acceptanceForm.enterFactoryDate = res.enterFactoryDate
        this.acceptanceForm.price = res.price
        this.acceptanceForm.technicalParameter = res.technicalParameter
        if (res.annexName) {
          const annexName = res.annexName.split(',')
          const annexUrl = res.annexUrl.split(',')
          this.fileList = []
          for (let i = 0; i < annexName.length; i++) {
            this.fileList.push({
              name: annexName[i],
              path: annexUrl[i]
            })
          }
        }
        this.acceptanceForm.installationSituation = res.installationSituation
        this.acceptanceForm.acceptanceConclusion = res.acceptanceConclusion
        this.acceptanceForm.acceptanceStaff = res.acceptanceStaff
        this.acceptanceForm.remarks = res.remarks
        this.acceptanceForm.useDeptConfirm = res.useDeptConfirm
        this.acceptanceForm.userDeptTime = res.userDeptTime
        this.acceptanceForm.purchaseDeptConfirm = res.purchaseDeptConfirm
        this.acceptanceForm.purchaseDeptTime = res.purchaseDeptTime
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectAcceptanceList(this.searchForm).then(res => {
        this.acceptancePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectAcceptanceList(this.searchForm).then(res => {
        this.acceptancePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectAcceptanceList(this.searchForm).then(res => {
        this.acceptancePage = res
      })
    },
    deviceChange (value) {
      if (value) {
        let device = this.deviceList.find(item => item.id === value)
        this.acceptanceForm.deviceName = device.deviceName
        this.acceptanceForm.deviceNo = device.deviceNo
        this.acceptanceForm.spec = device.spec
        this.acceptanceForm.manufacturer = device.manufacturer
        this.acceptanceForm.enterFactoryDate = device.enterFactoryDate.substring(0, 10)
      } else {
        this.acceptanceForm.deviceName = ''
        this.acceptanceForm.deviceNo = ''
        this.acceptanceForm.spec = ''
        this.acceptanceForm.manufacturer = ''
        this.acceptanceForm.enterFactoryDate = ''
      }
    },
    handleHttp (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload(data).then(res => {
        this.fileList.push({
          name: file.file.name,
          path: res,
          uid: file.file.uid
        })
      })
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    }
  }
}
</script>

<style>
#acceptance .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
