import axios from '@/common/axios'
import qs from 'qs'

export function addAcceptance (data) {
  return axios({
    method: 'post',
    url: '/device/acceptance/add',
    data: qs.stringify(data)
  })
}

export function deleteAcceptance (id) {
  return axios({
    method: 'delete',
    url: '/device/acceptance/delete/' + id
  })
}

export function updateAcceptance (data) {
  return axios({
    method: 'put',
    url: '/device/acceptance/update',
    data: qs.stringify(data)
  })
}

export function selectAcceptanceInfo (id) {
  return axios({
    method: 'get',
    url: '/device/acceptance/info/' + id
  })
}

export function selectAcceptanceList (query) {
  return axios({
    method: 'get',
    url: '/device/acceptance/list',
    params: query
  })
}
